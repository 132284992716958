import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"class-view"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('class-bio-panel',{attrs:{"class-data":_vm.classData},on:{"refetch-data":_vm.getClassData}})],1),_c(VCol,{attrs:{"cols":"12","md":"7","lg":"8"}},[_c(VTabs,{staticClass:"user-tabs",attrs:{"show-arrows":""},model:{value:(_vm.classTab),callback:function ($$v) {_vm.classTab=$$v},expression:"classTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.icon},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c(VTabsItems,{staticClass:"mt-5 pa-1",attrs:{"id":"class-tabs-content"},model:{value:(_vm.classTab),callback:function ($$v) {_vm.classTab=$$v},expression:"classTab"}},[_c(VTabItem,[_c('class-tab-pupils',{attrs:{"class-data":_vm.classData},on:{"refetch-data":_vm.getClassData}})],1),_c(VTabItem,[_c('class-tab-teachers',{attrs:{"class-data":_vm.classData},on:{"refetch-data":_vm.getClassData}})],1),_c(VTabItem,[_c('class-tab-security')],1),_c(VTabItem,[_c('class-tab-notifications')],1),_c(VTabItem,[_c('class-tab-connections')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }