<template>
  <div id="class-view">
    <v-row>
      <!-- Class bio panel -->
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <class-bio-panel
          :class-data="classData"
          @refetch-data="getClassData"
        ></class-bio-panel>
      </v-col>

      <!-- Class data panels and tabs -->
      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="classTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="class-tabs-content"
          v-model="classTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <class-tab-pupils
              :class-data="classData"
              @refetch-data="getClassData"
            ></class-tab-pupils>
          </v-tab-item>
          <v-tab-item>
            <class-tab-teachers
              :class-data="classData"
              @refetch-data="getClassData"
            ></class-tab-teachers>
          </v-tab-item>

          <v-tab-item>
            <class-tab-security></class-tab-security>
          </v-tab-item>
          <v-tab-item>
            <class-tab-notifications></class-tab-notifications>
          </v-tab-item>
          <v-tab-item>
            <class-tab-connections></class-tab-connections>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import {
mdiAccountSchoolOutline, mdiHumanMaleBoard
} from '@mdi/js'
import classStoreModule from '../classStoreModule'
import ClassBioPanel from './class-bio-panel/ClassBioPanel.vue'
import ClassTabPupils from './class-tab-pupils/ClassTabPupils.vue'
import ClassTabTeachers from './class-tab-teachers/ClassTabTeachers.vue'

export default {
  components: {
    ClassBioPanel,
    ClassTabPupils,
    ClassTabTeachers,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-class'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, classStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const classData = ref({})
    const classTab = ref(null)

    const getClassData = async () => {
      console.log('getClassData')
      store
        .dispatch('app-class/fetchClass', { id: router.currentRoute.params.id })
        .then(response => {
          classData.value = response.data.class
        })
        .catch(error => {
          if (error.response.status === 404) {
            classData.value = {}
          }
        })
    }
    getClassData()

    const tabs = [
      { icon: mdiAccountSchoolOutline, title: 'Leerlingen' },
      { icon: mdiHumanMaleBoard, title: 'Leerkrachten' },

      /*
      { icon: mdiLockOutline, title: 'Security' },
      { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' },
      */
    ]

    return {
      getClassData,
      tabs,
      classTab,
      classData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

<style>
.v-item-group.theme--dark.v-slide-group.v-tabs-bar.v-tabs-bar--is-mobile.v-tabs-bar--show-arrows.primary--text {
  background: transparent
}
</style>
