<template>
  <v-row class="class-bio-panel">
    <!-- class profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="mb-5">{{ classData.name }}</span>
        </v-card-title>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            block
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Bewerken
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <class-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :class-data="classData"
        :login-methods="loginMethods"
        :classes="classes"
        v-on="$listeners"
      ></class-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import axios from '@axios'
import { avatarText, kFormatter } from '@core/utils/filter'
import {
mdiAccountGroup, mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useClasssList from '../../class-list/useClassesList'
import ClassBioEdit from './ClassBioEdit.vue'

export default {
  components: {
    ClassBioEdit,
  },
  props: {
    classData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { resolveClassStatusVariant, resolveClassRoleVariant } = useClasssList()

    const isBioDialogOpen = ref(false)

    // Login methods
    const loginMethods = ref([])
    axios.get('/loginmethod').then(response => {
      loginMethods.value = response.data.loginMethods
    })

    const school = JSON.parse(localStorage.getItem('school'))

    // Classes
    const classes = ref([])
    axios.get(`/school/${school.id}/class`).then(response => {
      const preparedClasses = response.data.classes.map(classItem => ({
        value: classItem.id,
        title: classItem.name,
      }))
      classes.value = [
        {
          value: 'none',
          title: 'Geen',
          disabled: true,
        },
        ...preparedClasses,
      ]
    })

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Classs', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      resolveClassStatusVariant,
      resolveClassRoleVariant,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      loginMethods,
      classes,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiAccountGroup,
      },
    }
  },
}
</script>
