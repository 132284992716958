<template>
  <div class="user-tab-overview">
    <!-- class teachers list -->
    <v-card class="mb-7">
      <v-card-title>
        Leerkrachten
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="addTeacher"
        >
          Leerkracht toevoegen
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="classData.teachers"
        no-data-text="Geen leerkrachten gevonden"
        hide-default-footer
      >
        <!-- project -->
        <!-- name -->
        <template #[`item.firstName`]="{item}">
          <div class="d-flex align-center">
            <!-- Avatar -->
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="32"
            >
              <span
                class="font-weight-semibold"
              >{{ avatarText(item.firstName + ' ' + item.lastName.split(' ')[0]) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'users-teachers-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.firstName + ' ' + item.lastName }}
              </router-link>
              <small>@{{ item.firstName }}</small>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="onBtnDeleteTeacherClick(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Verwijderen</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from '@axios'
import { avatarText } from '@core/utils/filter'
import { mdiDeleteOutline, mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import swal from 'sweetalert2'

export default {
  props: {
    classData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isAddTeacherDialogOpen = ref(false)

    const school = JSON.parse(localStorage.getItem('school'))

    const onBtnDeleteTeacherClick = async item => {
      const response = await axios.delete(`/school/${school.id}/class/${props.classData.id}/teacher/${item.id}`)
      emit('refetch-data')
    }

    const addTeacher = () => {
      swal.fire('Leerkracht toevoegen?', 'Dat kun je doen via de pagina "leerkrachten". Klik daarna op de leerkracht door om deze klas eraan toe te kennen.', 'info')
    }

    const tableColumnHeaders = [
      {
        text: 'LEERLING',
        value: 'firstName',
        sortable: false,
      }, {
        text: 'ACTIES',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      isAddTeacherDialogOpen,
      tableColumnHeaders,
      onBtnDeleteTeacherClick,
      icons: {
        mdiDotsVertical,
        mdiDeleteOutline,
      },
      addTeacher,
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>

<style>
.swal2-popup {
    font-family: "Inter", sans-serif;
}
</style>
