import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"class-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[_c('span',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.classData.name))])]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.isBioDialogOpen = !_vm.isBioDialogOpen}}},[_vm._v(" Bewerken ")])],1)],1),_c('class-bio-edit',_vm._g({attrs:{"is-bio-dialog-open":_vm.isBioDialogOpen,"class-data":_vm.classData,"login-methods":_vm.loginMethods,"classes":_vm.classes},on:{"update:isBioDialogOpen":function($event){_vm.isBioDialogOpen=$event},"update:is-bio-dialog-open":function($event){_vm.isBioDialogOpen=$event}}},_vm.$listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }