import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-overview"},[_c(VCard,{staticClass:"mb-7"},[_c(VCardTitle,[_vm._v(" Leerkrachten "),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.addTeacher}},[_vm._v(" Leerkracht toevoegen ")])],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.classData.teachers,"no-data-text":"Geen leerkrachten gevonden","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"color":"primary","size":"32"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.avatarText(item.firstName + ' ' + item.lastName.split(' ')[0])))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'users-teachers-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.firstName + ' ' + item.lastName)+" ")]),_c('small',[_vm._v("@"+_vm._s(item.firstName))])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.onBtnDeleteTeacherClick(item)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Verwijderen")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }